function hideAfter(time, obj) {
	$(obj).data('opened', false);
	window.setTimeout(function() {
		if ($(obj).data('opened') == false) {
			$(obj).fadeOut(200);
		}
	}, time);
}

jQuery(document).ready (function () {
    //Change Caret of first layer
    jQuery('.menu-navbar-bar > .menu-navbar-list > li > a').hover(function() {
		$(this).next().fadeIn(200);
		$('.menu-navbar-bar > .menu-navbar-list ul').not($(this).next()).fadeOut(100);

		$(this).next().hover(function() {
			$(this).data('opened', true);
		}, function() {
			hideAfter(500, this);
		});
	}, function () {
		hideAfter(500, $(this).next());
	});

    jQuery('.stacked-menu > li > ul.collapse').on('hidden.bs.collapse', function (e) {
        var el = jQuery(e.target).prev().children('.caret-container');
        jQuery (el).toggleClass('dropup');
    });

    //Change Caret of second layer
    jQuery('.stacked-menu > li > ul > ul.collapse').on('shown.bs.collapse', function (e) {
        console.log (this);
        var el = jQuery(e.target).prev().children('.nav-sub-container').children('.caret-container');
        jQuery (el).toggleClass('dropup');
    });

    jQuery('.stacked-menu > li > ul > ul.collapse').on('hidden.bs.collapse', function (e) {
        var el = jQuery(e.target).prev().children('.nav-sub-container').children('.caret-container');
        jQuery (el).toggleClass('dropup');
    });
});
