jQuery(document).ready(function() {
	jQuery('button[data-toggle="own-navbar"]').click(function() {
		var navbar = jQuery(jQuery(this).data('target'));
		navbar.css('display', 'block');
	});
	jQuery('.own-menu-mobile-close').click(function () {
		var navbar = jQuery(jQuery(this).data('target'));
		navbar.css('display', 'none');
	});
});
